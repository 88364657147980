export enum SignalrEvent {
  /** # OHLC 報價 */
  Quote = 'quote',

  GetSymbols = 'GetSymbols',

  /** # 市場掛單 */
  TradeInfo = 'TradeInfo',

  /** # 五檔掛單簿 */
  BidAsk = 'BidAsk',

  /** # 逐筆成交 */
  Tick = 'Tick',
}
