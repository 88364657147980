/**
 * ! Copy from the source project
 * https://github.com/SamVerschueren/tsd/blob/master/source/lib/assertions/assert.ts
 */

/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Check that the type of `value` is identical to type `T`.
 *
 * @example <caption>指定特定字串</caption>
 *   return (
 *     <TradeTransactions
 *       render={scope => {
 *         type FieldName = keyof Trade.Transaction
 *
 *         return (
 *           <DataGrid
 *             columns={[
 *               { field: expectType<FieldName>('symbol') },
 *               { field: expectType<FieldName>('price') },
 *               { field: expectType<FieldName>('qty') },
 *               { field: expectType<FieldName>('transactionDateTime') },
 *             ]}
 *             rows={scope.transactions.map(item => {
 *               return {}
 *             })}
 *           ></DataGrid>
 *         )
 *       }}
 *     ></TradeTransactions>
 *   )
 *
 * @param value - Value that should be identical to type `T`.
 */
export const expectType = <T>(value: T) => {
  return value
}

/**
 * Check that the type of `value` is not identical to type `T`.
 *
 * @param value - Value that should be identical to type `T`.
 */
// @ts-ignore
export const expectNotType = <T>(value: any) => {
  // tslint:disable-line:no-unused
  // TODO Use a `not T` type when possible https://github.com/microsoft/TypeScript/pull/29317
  // Do nothing, the TypeScript compiler handles this for us
}

/**
 * Check that the type of `value` is assignable to type `T`.
 *
 * @param value - Value that should be assignable to type `T`.
 */
// @ts-ignore
export const expectAssignable = <T>(value: T) => {
  // tslint:disable-line:no-unused
  // Do nothing, the TypeScript compiler handles this for us
}

/**
 * Check that the type of `value` is not assignable to type `T`.
 *
 * @param value - Value that should not be assignable to type `T`.
 */
// @ts-ignore
export const expectNotAssignable = <T>(value: any) => {
  // tslint:disable-line:no-unused
  // Do nothing, the TypeScript compiler handles this for us
}

/**
 * Assert the value to throw an argument error.
 *
 * @param value - Value that should be checked.
 */
// @ts-ignore
export const expectError = <T = any>(value: T) => {
  // tslint:disable-line:no-unused
  // Do nothing, the TypeScript compiler handles this for us
}

/**
 * Assert that the `expression` provided is marked as `@deprecated`.
 *
 * @param expression - Expression that should be marked as `@deprecated`.
 */
// @ts-ignore
export const expectDeprecated = (expression: any) => {
  // tslint:disable-line:no-unused
  // Do nothing, the TypeScript compiler handles this for us
}

/**
 * Assert that the `expression` provided is not marked as `@deprecated`.
 *
 * @param expression - Expression that should not be marked as `@deprecated`.
 */
// @ts-ignore
export const expectNotDeprecated = (expression: any) => {
  // tslint:disable-line:no-unused
  // Do nothing, the TypeScript compiler handles this for us
}
