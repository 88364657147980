import Cookie from 'cookie-universal'
import { Dayjs } from 'dayjs'
import firebase from 'firebase/app'
import 'firebase/auth'
import jwt_decode from 'jwt-decode'
import { filter } from 'lodash'
import { useEffect, useState } from 'react'
import { useEffectOnce, useInterval } from 'react-use'
import { proxy, snapshot, useSnapshot } from 'valtio'
import { AgentProduct } from '~/configs/AgentProduct'
import { apirc } from '~/configs/apirc'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { getFirebaseApp } from '~/modules/SDK/firebase/getFirebase'
import { MeTypes } from '~/modules/SDK/me/MeTypes'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { fr_serverTime } from '~/pages/heineken_template/_fr/fr_serverTime'
import type { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import dayAPI from '~/utils/dayAPI'

const facebookProvider = new firebase.auth.FacebookAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()

export const fr_me = proxy(
  new (class FrMe {
    jwt = ''

    jwtInfo: null | MeTypes.DecodedJwtToken = null

    /**
     * - me 需要跟我們後端取值，先有 jwt 不一定同時有 me
     * - 有了 me；不一定同時有 meInfo（當他沒有修改個人資料）
     * - 沒有 jwt，則一致認為也沒有 me；即未登入
     */
    me = {
      get displayName() {
        return fr_me._ourMeInfo?.name || fr_me._ourMe?.name || fr_me._firebaseMe?.name || ''
      },
    }

    /** #### 詳見 {@link TemplateProps} */
    toTemplate = {
      /** #### 詳見 {@link TemplateProps.permissions} 的 .pageview */
      permissions: {
        /**
         * - 什麼都不檢查
         * - - 能看頁面，無論你是權限什模情況
         * - - 沒登入、沒訂閱、已過期：皆可看到畫面
         */
        alwaysAllow(): MeTypes.AddonAsTemplatePermissionPageview {
          return {
            check() {
              return true
            },
            useCheck() {
              return true
            },
          }
        },

        /**
         * - 只檢查用戶是否存在有 jwtInfo
         * - - 檢查用戶必須處於已登入狀態
         * - - - 必須要有 jwt, jwtInfo
         * - - - jwtInfo.exp 必須不過期
         * - - 其它不管：後端未訂閱, 後端已訂閱, etc
         */
        hasLogin(): MeTypes.AddonAsTemplatePermissionPageview {
          return {
            check() {
              const expiredAt = snapshot(fr_me).jwtInfo?.expiredAt

              const hasExpired = expiredAt ? expiredAt?.isBefore(dayAPI()) : true

              return !hasExpired
            },
            useCheck() {
              const watch = snapshot(fr_me).jwtInfo?.expiredAt

              return !!this.check()
            },
          }
        },

        /**
         * - 檢查用戶是否有「指定 AgentProduct」的後端訂閱權限
         * - - 後端訂閱權限必須不過期
         */
        hasPermission(
          checkAgentProduct: AgentProduct | string,
        ): MeTypes.AddonAsTemplatePermissionPageview {
          return {
            check() {
              return fr_me.hasPermission(checkAgentProduct)
            },
            useCheck() {
              const watch = useSnapshot(fr_me)._ourMe?.subscriptions

              return fr_me.hasPermission(checkAgentProduct)
            },
          }
        },

        /**
         * - 已登入有權限，或者
         * - 已登入沒權限但「當前伺服器時間」在「指定時間」之前
         *
         * @example
         *   //
         *   // 飯粒
         *   templateProps.hooks.add(fr_serverTime.useInstall)
         *   templateProps.hooks.add(fr_me.useInstall)
         *
         *   templateProps.permissions.pageview =
         *     fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
         *       agentProduct: AgentProduct['futuresai@web'],
         *       date: dayAPI('2023-03-12 08:20:00+8'),
         *     })
         */
        hasPermissionOrHasLoginBeforeServerDate(options: {
          agentProduct: AgentProduct
          date: Dayjs
        }): MeTypes.AddonAsTemplatePermissionPageview {
          const hasLogin = this.hasLogin
          const hasPermission = this.hasPermission

          return {
            check() {
              return (
                ((hasLogin().check() && fr_serverTime.state.serverTime?.isBefore(options.date)) ||
                  hasPermission(options.agentProduct).check()) ??
                false
              )
            },
            useCheck() {
              useSnapshot(fr_serverTime).state.serverTime
              return this.check()
            },
          }
        },

        /**
         * - 開放已登入用戶（無論權限）到指定日期之前
         * - - 檢查已登入
         * - - 檢查當下日期在指定時間之前
         * - - 每分鐘會重新檢查一次
         */
        allowBeforeDate(date: Dayjs): MeTypes.AddonAsTemplatePermissionPageview {
          return {
            check() {
              return dayAPI().isBefore(date)
            },
            useCheck() {
              return dayAPI().isBefore(date)
            },
          }
        },
      },
    }

    /** 存於我們後端的會員資料、會員訂閱資料、何時過期 */
    _ourMe: null | MeTypes.MeUserState = null

    /** 存於我們後端的會員資料、電話、顯示名稱等 */
    _ourMeInfo: null | MeTypes.MeInfoState = null

    /** 存於 firebase端 的會員資料、jwt */
    _firebaseMe: null | MeTypes.MeFirebaseState = null

    async logoutWithFirebase() {
      await getFirebaseApp().auth().signOut()
      fr_me._ourMe = null
      fr_me._ourMeInfo = null
      fr_me._firebaseMe = null
      fr_me.setJwt(null)
    }

    async loginWithGoogle() {
      try {
        await getFirebaseApp().auth().signInWithPopup(googleProvider)
      } catch (err) {
        fr_me._ourMe = null
        fr_me._ourMeInfo = null
        fr_me._firebaseMe = null
        fr_me.setJwt(null)
      }
    }

    async loginWithFacebook() {
      try {
        await getFirebaseApp().auth().signInWithPopup(facebookProvider)
      } catch (err) {
        fr_me._ourMe = null
        fr_me._ourMeInfo = null
        fr_me._firebaseMe = null
        fr_me.setJwt(null)
      }
    }

    async fetchNewToken() {
      const forceUpdate = true

      const jwt = await getFirebaseApp().auth().currentUser?.getIdToken(forceUpdate)

      return jwt
    }

    setJwt(jwt: undefined | null | string) {
      debugAPI.fr_me.log(`setJwt()`, { jwt })
      if (!jwt) {
        fr_me.jwt = ''
        fr_me.jwtInfo = null
        this._cookies.set('jwt', '')
        return
      }

      const $jwtInfo = jwt_decode(jwt) as MeTypes.DecodedJwtToken

      fr_me.jwt = jwt
      fr_me.jwtInfo = {
        ...$jwtInfo,
        expiredAt: dayAPI(($jwtInfo.exp ?? 0) * 1000),
      }
      this._cookies.set('jwt', jwt)
    }

    getJwt() {
      return (this._cookies.get('jwt') as string) || ''
    }

    findPermissions(expects: {
      /**
       * - 預設 `''`
       * - - 預設不作篩選
       *
       * @example
       *   //
       *   // 個別策略的話，也許 `productName` 屬於變數
       *   // 所以無法列舉在 `AgentProduct`
       *   // 所以以下允許
       *   expect(byAgentProduct).toBe('futuresai@_MASS_SEAN')
       *   expect(byAgentProduct).toBe('futuresai@_RVGI')
       *   expect(byAgentProduct).toBe('futuresai@_VWMA_')
       */
      agentProductToBe?: string

      /**
       * - 預設 `''`
       * - - 預設不作篩選
       *
       * @example
       *   //
       *   // 撈出全部與 futuresai 有關的訂閱權限
       *   expect(agentNameIncludes).toBe('futuresai')
       */
      agentNameIncludes?: string

      /**
       * - 預設 `false`
       * - - 預設不包含過期
       */
      expiredIncludes?: boolean
    }) {
      if (!fr_me.jwt || !fr_me._ourMe?.subscriptions.length) {
        return []
      }

      return filter(fr_me._ourMe?.subscriptions || [], subscription => {
        const agentProductIncludes = !expects.agentNameIncludes
          ? true
          : expects.agentNameIncludes === subscription.agentName

        const agentProductToBe = !expects.agentProductToBe
          ? true
          : expects.agentProductToBe === `${subscription.agentName}@${subscription.productName}`

        const expiredIncludes = expects.expiredIncludes
          ? true
          : dayAPI().isBefore(dayAPI(subscription.expiredAt))

        return agentProductIncludes && agentProductToBe && expiredIncludes
      })
    }

    hasPermission(checkAgentProduct: AgentProduct | string) {
      if (!fr_me.jwt) {
        return false
      }

      const foundPermission = fr_me.findPermissions({ agentProductToBe: checkAgentProduct })

      return !!foundPermission.length
    }

    /**
     * - 每個 NextPage 皆只需要 useInit() 一次。
     * - 如果未來在 TemplatePage 底層實裝，則以後都不須要手動 useInit()。
     */
    useInstall = () => {
      const jwt = useSnapshot(fr_me).jwt

      //
      // 初始化工作
      useEffectOnce(() => {
        debugAPI.fr_me.log(`useInstall(): 已安裝`)
        const unsub = getFirebaseApp()
          .auth()
          .onIdTokenChanged(user => {
            debugAPI.fr_me.log(`onIdTokenChanged`, {
              firebaseUser: user,
              get fr_me() {
                return fr_me
              },
            })

            if (!user) {
              useMeStore.setState({ meFirebaseState: null })
              fr_me._firebaseMe = null
              fr_me.setJwt(null)
              return
            }

            fr_me._firebaseMe = proxy({
              email: user?.email || '',
              name: user?.displayName || '',
              avatarUrl: user?.photoURL || '',
              providerUid: user?.providerData[0]?.uid || '',
              provider: user?.providerData[0]?.providerId || '',
              uid: user?.uid || '',
            })
            /**
             * 兼容 useMeStore
             *
             * 避免在「非fr_me頁面」掛網到過期然後切去「fr_me頁面」再切回「非fr_me頁面」之後，useMeStore無資料造成權限驗不過
             */
            useMeStore.setState({ meFirebaseState: fr_me._firebaseMe })

            user?.getIdToken().then(jwt$$ => {
              fr_me.setJwt(jwt$$)
              debugAPI.fr_me.log(`getIdToken()`, { accessJwtInfo: fr_me.jwtInfo })
            })
          })

        return () => {
          debugAPI.fr_me.log(`useInstall(): 解除安裝`)
          unsub()
        }
      })

      //
      // 如果 jwt 發生變化了，那麼就向後端展開行動
      useEffect(() => {
        if (jwt) {
          // 不管三七二十一先送註冊，以免漏掉新客戶
          // aqzhyi: 如果一直 POST 同個人會怎樣？不會有反應就是了對ㄇ
          // YuCheng: 會回傳已存在，所以其實沒差
          apirc.me.api.signup.fetch().catch(() => {
            apirc.me.api.signup.fetch()
          })
        }

        //
        // me
        if (jwt && !fr_me._ourMe) {
          debugAPI.fr_me.log(`getMe()`)

          fr_me.setJwt(jwt)

          apirc.me.api.getMe
            .fetch()
            .then(me => {
              if (me) {
                fr_me._ourMe = proxy(me)
              }
            })
            .catch(() => {
              fr_me._ourMe = null
            })
        }

        //
        // me info
        if (jwt && !fr_me._ourMeInfo) {
          debugAPI.fr_me.log(`getMeInfo()`)

          apirc.me.api.getInfo
            .fetch()
            .then(info => {
              if (info) {
                fr_me._ourMeInfo = proxy(info)
              }
            })
            .catch(() => {
              fr_me._ourMeInfo = null
            })
        }
      }, [jwt])

      //
      // 檢查 token 過期機制
      useInterval(() => {
        const jwtExpAt = fr_me.jwtInfo?.expiredAt

        if (!jwtExpAt) return

        /**
         * 刷新 token
         *
         * - 當即將過期，的提前5分鐘
         * - 當已過期
         */
        const shouldRefreshToken =
          jwtExpAt.isBefore(dayAPI().subtract(5, 'minute')) || jwtExpAt.isBefore(dayAPI())

        debugAPI.fr_me.log(`檢查 token 過期機制`, {
          jwtExp: fr_me.jwtInfo?.exp,
          jwtExpAt: jwtExpAt.format('YYYY-MM-DD HH:mm:ss'),
          shouldRefreshToken,
        })

        if (shouldRefreshToken) {
          debugAPI.fr_me.log('刷新 token...')
          fr_me.fetchNewToken().then(jwt$$ => {
            fr_me.setJwt(jwt$$)
          })
        }
      }, 1 * 1000 * 60)
    }

    private _cookies = Cookie()
  })(),
)
