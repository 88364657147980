import { css, SerializedStyles } from '@emotion/react'
import { Avatar, Button, IconButton } from '@mui/material'
import React, { memo } from 'react'
import { MdPerson } from 'react-icons/md'
import { useSnapshot } from 'valtio'
import { globalRed, WHITE } from '~/modules/AppLayout/Colors'
import { authDialogState } from '~/modules/auth/containers/authDialogStore'
import { meCheckHandlerTrialCode } from '~/modules/SDK/me/meCheckHandlerTrialCodeAndProductWeb'
import { useMeStore } from '~/modules/SDK/me/useMeStore'

export const UserAvatarAsDialogButton = memo<{
  rootCSS?: SerializedStyles
  loginAsText?: string
}>(function UserAvatarAsDialogButton(props) {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)
  const snap = useSnapshot(authDialogState)
  const hasTrialCode = meCheckHandlerTrialCode.useCheck()
  const basisCSS = !props.loginAsText
    ? css`
        height: 40px;
        width: 40px;
        padding: 0;
      `
    : css``

  const rootProps = {
    onClick: () => {
      if (!snap.open) {
        authDialogState.open = true
      }
    },
    css: css`
      ${basisCSS};
      ${props.rootCSS};
    `,
  }

  return hasTrialCode ? (
    <Button
      {...rootProps}
      css={css`
        color: ${globalRed.rA700};
        font-size: 0.8rem;
      `}
    >
      序號體驗中
    </Button>
  ) : meFirebaseState ? (
    <IconButton
      data-testid={testids.hasSignIn}
      {...rootProps}
    >
      <Avatar
        alt={meFirebaseState.name ?? ''}
        src={meFirebaseState.avatarUrl ?? undefined}
      />
    </IconButton>
  ) : props.loginAsText ? (
    <Button
      variant='contained'
      {...rootProps}
    >
      {props.loginAsText}
    </Button>
  ) : (
    <IconButton
      data-testid={testids.notSignIn}
      {...rootProps}
    >
      <MdPerson data-testid={testids.avatarOfNotSignIn} />
    </IconButton>
  )
})

export const testids = {
  hasSignIn: 'hasSignIn',
  notSignIn: 'notSignIn',
  avatarOfNotSignIn: 'avatarOfNotSignIn',
}
