import dayjs from 'dayjs'
import firebase from 'firebase/app'
import 'firebase/auth'
import jwt_decode from 'jwt-decode'
import isError from 'lodash/isError'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useEffectOnce, useLifecycles, useTimeout, useUpdateEffect } from 'react-use'
import { Nullable } from 'tsdef'
import { apirc } from '~/configs/apirc'
import { useAppTrans } from '~/hooks/useAppTrans'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { eventEmitter } from '~/modules/SDK/Events/eventEmitter'
import { EventString } from '~/modules/SDK/Events/EventString'
import { MeTypes } from '~/modules/SDK/me/MeTypes'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { useSlack } from '~/modules/SDK/slack/useSlack'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'
import dayAPI from '~/utils/dayAPI'
import { __DEV__ } from '~/utils/__DEV__'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyCn5dseBaKD4ESxCFdRvqNasYVVsDmtNs4',
    authDomain: 'futures-ai.firebaseapp.com',
    databaseURL: 'https://futures-ai.firebaseio.com',
    projectId: 'futures-ai',
    storageBucket: 'futures-ai.appspot.com',
    messagingSenderId: '463877906717',
  })
}

const facebookProvider = new firebase.auth.FacebookAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()

/**
 * OnAuthStateChanged:
 *
 * - V4前：可以監聽登入、登出、token changed、密碼更改。
 * - V4後：只能監聽登入、登出（我們是v8）
 *
 * OnIdTokenChanged:
 *
 * - `登入`
 * - `登出`
 * - `token refresh`(是client去觸發的)。
 */
export const useFirebaseMe = () => {
  const router = useRouter()
  const { t } = useAppTrans()
  const { current: app } = useRef(firebase.app())
  const slack = useSlack()
  const [jwtExpire, setJwtExpire] = useState<number>()
  const [meState, setMeState] = useState<{
    name: Nullable<string>
    uid: Nullable<string>
    email: Nullable<string>
    avatarUrl: Nullable<string>
    jwt?: Nullable<string>
    provider?: Nullable<string>
    providerUid?: Nullable<string>
  } | null>(null)

  const [isAuthorizing, setIsAuthorizing] = useState(false)

  const [error, setError] = useState<Nullable<Error>>()

  const updateMeState = useCallback(async (user?: firebase.User | null) => {
    if (user) {
      const jwt = await user.getIdToken()
      const meFirebaseState = {
        name: user.displayName,
        uid: user.uid,
        email: user.email,
        avatarUrl: user.photoURL,
        providerUid: user.providerData[0]?.uid,
        provider: user.providerData[0]?.providerId,
        jwt,
      }
      const jwtInfo = jwt_decode(meFirebaseState.jwt) as MeTypes.DecodedJwtToken
      setError(null)
      apirc.elk.uid = user.uid
      setMeState(meFirebaseState)
      useMeStore.setState({ meFirebaseState })

      /** 人工縮短 refreshToken 時間方式 */
      const shouldShorter = location.href.includes('refresh_token_shorter')
      setJwtExpire(jwtInfo.exp - (shouldShorter ? 3440 : 0))
      debugAPI.firebaseMe.log(
        `[提示] 將 refreshToken 時長縮短為數分鐘內方式，在網址後面加上即可，例如 localhost:2886/hyt888?refresh_token_shorter`,
      )

      apirc.elk.sendMessage('firebase useMeStore.setState({ meFirebaseState })', {
        meFirebaseState,
        jwtInfo,
        jwt,
      })
    } else {
      apirc.elk.uid = null
      setMeState(null)
      useMeStore.setState({ meFirebaseState: null })
      apirc.elk.sendMessage('firebase useMeStore.setState({ meFirebaseState: null })')
    }
  }, [])

  const updateAuthorizingState = useCallback(() => {
    setIsAuthorizing(false)
  }, [])

  const castError = useCallback(
    (incomingError: firebase.FirebaseError) => {
      setMeState(null)

      const errorMessage =
        typeof incomingError?.code === 'string'
          ? t(`_firebaseErrorCode_${incomingError.code}`)
          : t(`_firebaseErrorCode_auth/custom/unexpected-error`)

      const isHumanCanRead = !errorMessage.startsWith('_')

      if (isHumanCanRead) {
        const error_ = new Error(errorMessage)
        apirc.elk.sendMessage(error_, { incomingError, errorMessage: incomingError.message })
        setError(error_)
      } else {
        const error_ = new Error(t(`_firebaseErrorCode_auth/custom/unexpected-error`))
        apirc.elk.sendMessage(error_, { incomingError, errorMessage: incomingError.message })
        setError(error_)
      }

      setIsAuthorizing(false)
    },
    [setIsAuthorizing, t],
  )

  const refreshToken = useCallback(() => {
    console.error('[DEBUG] jwt 可能已過期！！但在這邊暫時性不真的執行 refreshToken()')
    apirc.elk.sendMessage(`jwt 可能已過期！！但在這邊暫時性不真的執行 refreshToken()`)
    // const user = app.auth().currentUser
    // user?.getIdToken(true)
  }, [])

  const loginWithGoogle = useCallback(() => {
    setIsAuthorizing(true)
    return app
      .auth()
      .signInWithPopup(googleProvider)
      .then(value => {
        apirc.elk.uid = value.user?.uid || null
        apirc.elk.sendMessage(`firebase google 驗證成功`)
      })
      .then(updateAuthorizingState)
      .catch(castError)
  }, [app, updateAuthorizingState, castError])

  const loginWithFacebook = useCallback(() => {
    setIsAuthorizing(true)
    app
      .auth()
      .signInWithPopup(facebookProvider)
      .then(value => {
        apirc.elk.uid = value.user?.uid || null
        apirc.elk.sendMessage(`firebase facebook 驗證成功`)
      })
      .then(updateAuthorizingState)
      .catch(castError)
  }, [app, updateAuthorizingState, castError])

  const loginWithMailAndPassword = useCallback(
    (email: string, password: string) => {
      setIsAuthorizing(true)
      app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(value => {
          apirc.elk.uid = value.user?.uid || null
          apirc.elk.sendMessage(`firebase password 驗證成功`)
        })
        .then(updateAuthorizingState)
        .catch(castError)
    },
    [app, updateAuthorizingState, castError],
  )

  const logout = useCallback(() => {
    app
      .auth()
      .signOut()
      .then(() => {
        apirc.elk.sendMessage(`firebase 已登出`)
        router.reload()
      })
  }, [app, router])

  // function above will trigger this event.
  useEffectOnce(() => {
    // NOTED: 外面hook管理refresh Token也會觸發這裡改動
    const unsub = app.auth().onIdTokenChanged(user => {
      updateMeState(user)
      apirc.elk.sendMessage(`onIdTokenChanged() -> updateMeState(user)`, { user })
    })

    return () => unsub()
  })

  // -----
  // 以避免以下的 `refreshToken()` 在 tab 切換時，立即地被 re-render
  const [shouldRefreshTimer, cancelRefreshTimer, resetRefreshTimer] = useTimeout(
    /** 1小時 */
    1000 * 60 * 60,
  )

  const refreshTokenForVisibilityChange = (data: { state: 'visible' | 'hidden' | 'prerender' }) => {
    if (shouldRefreshTimer() && data.state === 'visible') {
      resetRefreshTimer()
      refreshToken()
    }
  }

  useLifecycles(
    () => eventEmitter.on(EventString.visibilityChange, refreshTokenForVisibilityChange),
    () => eventEmitter.off(EventString.visibilityChange, refreshTokenForVisibilityChange),
  )
  // -----

  useEffect(() => {
    /** JwtExpire 的兼容瀏覽器位數 */
    const jwtExpire_ = (jwtExpire ?? 0) * 1000
    const jwtExpireDayjs = jwtExpire_ > 0 ? dayAPI(jwtExpire_) : null

    const now = dayAPI()
    /**
     * 雖然目前不影響tvChart的運作，設定早一分鐘重拿token
     *
     * @example <caption>原公式</caption>
     *   jwtExpire ? (jwtExpire - dayjs().subtract(1, 'minute').unix()) * 1000 : 10000
     */
    const millisecondsToCheckRefresh = jwtExpireDayjs
      ? (jwtExpireDayjs.unix() - now.subtract(1, 'minute').unix()) * 1000
      : 10000

    const timer = setTimeout(() => {
      if (jwtExpire) {
        apirc.elk.sendMessage(`jwtExpire`, {
          jwtExpire: jwtExpire,
          jwtExpire_: jwtExpire_,
          jwtExpire_formatted: jwtExpireDayjs?.format('YYYY-MM-DD HH:mm:ss'),
          jwtExpire_relative: jwtExpireDayjs?.fromNow(),
          millisecondsToCheckRefresh,
        })
        refreshToken()
      }
    }, millisecondsToCheckRefresh)
    return () => clearTimeout(timer)
  }, [jwtExpire, refreshToken, slack])

  return useMemo(() => {
    return {
      firebaseMe: meState,
      isAuthorizing,
      error,
      acts: {
        loginWithGoogle,
        loginWithFacebook,
        loginWithMailAndPassword,
        logout,
        refreshToken,
      },
    }
  }, [
    meState,
    error,
    loginWithGoogle,
    loginWithFacebook,
    loginWithMailAndPassword,
    logout,
    refreshToken,
    isAuthorizing,
  ])
}

export const useFirebaseMeState = createContainer(useFirebaseMe)
