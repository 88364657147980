import { AxiosInstance } from 'axios-0-27-2'
import urlcat from 'urlcat'
import { Trade } from '~/modules/SDK/Trade/Trade'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { expectType } from '~/utils/tsd'

export type StrategiesSCResourceData = {
  strategyId: number
  strategyName: string
  symbol: string
  price: number
  datetime: string
  qty: number
  winRate1M: number
  profit1M: number
  drawdown1M: number
  winRate3M: number
  profit3M: number
  drawdown3M: number
  winRate6M: number
  profit6M: number
  drawdown6M: number
  winRate1Y: number
  profit1Y: number
  drawdown1Y: number
  winRateYtd: number
  profitYtd: number
  drawdownYtd: number
  winRateAll: number
  profitAll: number
  drawdownAll: number
}

export class StrategyAPI extends Urlu {
  /** 這邊專用的 jwt */
  get request() {
    return super.request.withJwt('futuresai')
  }

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useStrategiesResource = () => {
   *     const url = urlcat(strategyCenterURL, 'api/overviews', { simulated: true })
   *     const res = useSWR<StrategiesSCResourceData[]>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data ?? []
   *   }
   */
  getAllOverviews = new Urlu.Query(() => {
    return this.request.axios
      .get<StrategiesSCResourceData[]>(`/overviews`, {
        params: {
          simulated: true,
        },
      })
      .then(res => {
        return res.data
      })
  })

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyOverview = (strategyName: string) => {
   *     const url = urlcat(strategyCenterURL, 'api/overviews/:name', {
   *       name: strategyName,
   *       simulated: true,
   *     })
   *     const res = useSWR<StrategiesSCResourceData>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data
   *   }
   */
  getOverview = new Urlu.Query((strategyName: string) => {
    if (!strategyName) {
      return undefined
    }

    return this.request.axios
      .get<StrategiesSCResourceData>(`/overviews/:name`, {
        params: {
          name: strategyName,
          simulated: true,
        },
      })
      .then(res => {
        return res.data
      })
  })

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyInfo = (strategyName: string) => {
   *     const url = urlcat(strategyCenterURL, 'api/strategies/:name', {
   *       name: strategyName,
   *       simulated: true,
   *     })
   *     const res = useSWR<Trade.Strategy>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data
   *   }
   */
  getStrategy = new Urlu.Query((strategyName: string) => {
    if (!strategyName) {
      return undefined
    }

    return this.request.axios
      .get<Trade.Strategy>(`/strategies/:name`, {
        params: {
          name: strategyName,
          simulated: true,
        },
      })
      .then(res => {
        return res.data
      })
  })

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyStatement = (
   *     strategyName: string,
   *     from?: string,
   *     to?: string,
   *   ) => {
   *     const url = urlcat(strategyCenterURL, 'api/strategies/:name/transactions/statements', {
   *       name: strategyName,
   *       beginDatetime: from,
   *       endDatetime: to,
   *       simulated: true,
   *     })
   *     const res = useSWR<Trade.Statement[]>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data ?? []
   *   }
   */
  getStrategyTransactionStatement = new Urlu.Query(
    (params: { strategyName: string; from?: string; to?: string }) => {
      return this.request.axios
        .get<Trade.Statement[]>(`/strategies/:name/transactions/statements`, {
          params: {
            name: params.strategyName,
            beginDatetime: params.from,
            endDatetime: params.to,
            simulated: true,
          },
        })
        .then(res => {
          return res.data
        })
    },
  )

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyDailyProfit = (
   *     strategyName: string,
   *     from?: string,
   *     to?: string,
   *   ) => {
   *     const url = urlcat(strategyCenterURL, 'api/strategies/:name/dailyProfit', {
   *       name: strategyName,
   *       beginDatetime: from,
   *       endDatetime: to,
   *       simulated: true,
   *     })
   *     const res = useSWR<Trade.DailyProfit[]>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data ?? []
   *   }
   */
  getStrategyDailyProfit = new Urlu.Query(
    (params: { strategyName: string; from?: string; to?: string }) => {
      return this.request.axios
        .get<Trade.DailyProfit[]>(`/strategies/:name/dailyProfit`, {
          params: {
            name: params.strategyName,
            beginDatetime: params.from,
            endDatetime: params.to,
            simulated: true,
          },
        })
        .then(res => {
          return res.data
        })
    },
  )
}
