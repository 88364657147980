import axios from 'axios'
import debug_ from 'debug'
import dedent from 'dedent'
import { cloneDeep, isArray, isFunction, isNumber, isObject, isString, map } from 'lodash'
import { __NAMESPACE__ } from '~/configs/__NAMESPACE__'
import { __DEV__ } from '~/utils/__DEV__'
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__'
import { __TEST__ } from '~/utils/__TEST__'

const scope = debug_(__NAMESPACE__)

class Debug {
  logger: debug_.Debugger

  constructor(instance: debug_.Debugger) {
    this.logger = instance
    this.notifyNamespaceSheetCheat()
  }

  /**
   * - 內建 cloneDeep 所以不會有 pass by references 的問題
   * - 當「未啟用時」不會執行 cloneDeep 所以不消耗性能
   */
  log(msg: unknown, ...msgs: unknown[]) {
    if (!this.logger.enabled) return
    msg = typeof msg === 'object' ? cloneDeep(msg) : msg
    msgs = map(msgs, $msg => (typeof $msg === 'object' ? cloneDeep($msg) : $msg))

    this.logger(msg, ...msgs)
  }

  logIf(
    ifTruePredicate: (...args: AnyExplicit[]) => boolean,
    msg: AnyExplicit,
    ...msgs: AnyExplicit[]
  ) {
    if (ifTruePredicate()) {
      this.log.bind(this, msg, ...msgs)()
    }
  }

  enable() {
    const debugString = localStorage.getItem('debug')
    const debugNamespaces = debugString?.split(',') || []

    debugNamespaces.push(this.logger.namespace)
    debugNamespaces.push(this.logger.namespace + ':*')

    localStorage.setItem('debug', debugNamespaces.join(','))
    console.warn(`%cReload 之後才會生效。請按 ⌘+R 手動 Reload`, 'font-size:16px')
  }

  disable() {
    debug_.disable()
  }

  throwErrorAndLog(msg: string) {
    this.logger(`[ERROR] ${msg}`)
    throw new Error(msg)
  }

  /** 不要洗版太多次，只提示第一次 */
  private isNamespaceSheetCheatNotified = false

  /** 提示開發者，有可用的 logs 可以開啟瀏覽 */
  private notifyNamespaceSheetCheat() {
    const enabled = this.logger.enabled
    const enabledHelperText = enabled ? '✅ 已啟用' : '🟨 可啟用'

    // eslint-disable-next-line no-console
    if (!__TEST__) {
      console.info(
        `%c${enabledHelperText} ` +
          `debugAPI.${this.logger.namespace.replace(
            new RegExp(__NAMESPACE__ + ':', 'i'),
            '',
          )}.enable()`,
        `color:#f57105;`,
      )
    }

    this.isNamespaceSheetCheatNotified = true
  }
}

export const debugAPI = {
  ...scope,
  async _sendTextToSlack(text = '') {
    await axios.post(
      `https://hooks.slack.com/services/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`,
      JSON.stringify({ text: text }),
      {
        headers: {},
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            headers['Content-Type'] = undefined
            headers['content-type'] = undefined
            return data
          },
        ],
      },
    )
  },
  async sendToSlack(...data: any[]) {
    const requests = map(data, async datum => {
      let text = ``

      if (isString(datum)) {
        text = `\`'${datum}'\``
      } else if (isNumber(datum)) {
        text = `\`${datum}\``
      } else if (isArray(datum) || isObject(datum)) {
        text = dedent(`
          \`\`\`
          ${dedent(JSON.stringify(datum, null, 2))}
          \`\`\`
        `)
      } else if (isFunction(datum)) {
        text = datum.toString()
      } else {
        text = `${text}`
      }

      return await debugAPI._sendTextToSlack(text)
    })

    await Promise.all(requests)
    await debugAPI._sendTextToSlack('<!-- ----- ----- ----- ----- --end>')
  },
  disableAll() {
    debug_.disable()
  },
  template: new Debug(scope.extend('template')),
  FuiSymbolWatch: new Debug(scope.extend('FuiSymbolWatch')),
  liteChart: new Debug(scope.extend('liteChart')),
  signalr: new Debug(scope.extend('signalr')),
  signalrQuote: new Debug(scope.extend('signalrQuote')),
  signalrV2: new Debug(scope.extend('signalrV2')),
  datafeed: new Debug(scope.extend('datafeed')),
  datafeed2: new Debug(scope.extend('datafeed2')),
  symbol_TX1: new Debug(scope.extend('symbol_TX1')),
  chart4: new Debug(scope.extend('chart4')),
  chart3: new Debug(scope.extend('chart3')),
  chart2: new Debug(scope.extend('chart2')),
  useSoundStore: new Debug(scope.extend('useSoundStore')),
  firebaseMe: new Debug(scope.extend('firebaseMe')),
  /** 盤勢轉折 */
  pivot: new Debug(scope.extend('pivot')),
  /** 大戶能量 */
  strength: new Debug(scope.extend('strength')),
  me: new Debug(scope.extend('me')),
  fr_me: new Debug(scope.extend('fr_me')),
  fr_events: new Debug(scope.extend('fr_events')),
  meRestAPI: new Debug(scope.extend('meRestAPI')),
  pages: new Debug(scope.extend('pages')),
  perf: new Debug(scope.extend('perf')),
  elk: new Debug(scope.extend('elk')),
  fr_instrument: new Debug(scope.extend('fr_instrument')),
  fr_serverTime: new Debug(scope.extend('fr_serverTime')),
}

/* istanbul ignore next */
if (__DEV__ && __IS_CLIENT__) {
  // eslint-disable-next-line no-console
  console.log(
    '%c⭐️ 透過「window.debug.XXX.enable()」來打開日誌系統；記得還要打開 console 面版的篩選器，選取顯示「Verbose」層級（被預設關閉）',
    'font-size:16px',
  )
}
