import { css, Global } from '@emotion/react'

/** @deprecated 這套設計出來後，大伙沒有共識，在隨後加零有一套新的 theme 模式，遵守她的，勿多頭馬車 */
export const createTheme = (themeConfig: AppThemeConfig) => {
  return {
    GlobalBaseline() {
      return (
        <Global
          styles={css`
            #__body {
              ${themeConfig.body}

              ${Object.entries(themeConfig.components).map((key, CSS) => {
                return css`
                  ${key} {
                    label: theme-${key};
                    ${CSS}
                  }
                `
              })}
            }
          `}
        />
      )
    },
  }
}
