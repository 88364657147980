import { useEffect } from 'react'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { __DEV__ } from '~/utils/__DEV__'
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__'
import { __PRODUCTION__ } from '~/utils/__PRODUCTION__'
import { __TEST__ } from '~/utils/__TEST__'

/** @private 在 DEV 環境自動切換 AgentName，而在 PROD 環境則以 env 給予 */
const isDevClientSide = (__DEV__ || __TEST__) && __IS_CLIENT__

/**
 * 確保 env.DEV 環境自動依 route 反應 AgentName 以及相關屬性值
 *
 * - 不會作用於 env.PROD
 */
export const useMeEnsureDevAgentContinue = () => {
  UseMeEnsureDevAgentContinueLog()

  useEffect(() => {
    useMeStore.getState().init()
  }, [])
}

const UseMeEnsureDevAgentContinueLog = () => {
  if (!debugAPI.me.logger.enabled) {
    return
  }

  if (isDevClientSide) {
    debugAPI.me.log(`當前環境=DEV; 已切換 state=`, useMeStore.getState())
  } else if (__PRODUCTION__) {
    debugAPI.me.log(
      `當前環境=PROD; 目前 .env=`,
      {
        NEXT_BUILD_TARGET: process.env.NEXT_BUILD_TARGET,
        NEXT_PUBLIC_TARGET_AGENT_PRODUCT: process.env.NEXT_PUBLIC_TARGET_AGENT_PRODUCT,
        NEXT_PUBLIC_PAGE_FOLDER: process.env.NEXT_PUBLIC_TARGET_PAGES,
      },
      'state=',
      useMeStore.getState(),
    )
  }
}
