import { Dayjs } from 'dayjs'
import { LiteralUnion } from 'type-fest'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import { SignalWallTypes } from '~/modules/signal-wall/SignalWallTypes'

export class SignalWallAPI extends Urlu {
  data = new Urlu.Query(
    (params: {
      signal_id: LiteralUnion<SignalWallId, string>
      symbol?: string

      /** 預設：一小時前 */
      from?: Dayjs

      /** 預設：當下 */
      to?: Dayjs

      /** 預設：1 */
      page?: number

      /** 預設：20 */
      size?: number
    }) => {
      return this.request.axios
        .get<SignalWallTypes.Datum[]>(this.baseUrl, {
          params: {
            ...params,
            from: params.from?.unix(),
            to: params.to?.unix(),
            from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
            to__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
          },
        })
        .then(res => res.data)
    },
  )

  /**
   * # @deprecated
   *
   * ## 改用 {@link SignalWallAPI.data}
   */
  async get(params: {
    signal_id: LiteralUnion<SignalWallId, string>
    symbol?: string

    /** 預設：一小時前 */
    from?: Dayjs

    /** 預設：當下 */
    to?: Dayjs

    /** 預設：1 */
    page?: number

    /** 預設：20 */
    size?: number
  }) {
    const { data } = await this.request.axios.get<SignalWallTypes.Datum[]>(this.baseUrl, {
      params: {
        ...params,
        from: params.from?.unix(),
        to: params.to?.unix(),
        from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
        to__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
      },
    })

    return data
  }
}
