import { css } from '@emotion/react'
import { TextField, Button } from '@mui/material'
import React, { memo, useState } from 'react'
import useMedia from '~/hooks/useMedia'
import { WHITE, globalRed } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { apirc } from '~/configs/apirc'
import { useText } from '~/hooks/useText'
import { adminNotifyPushStore } from '~/modules/admin/adminStore'

const ChangePasswordForm = memo<ReactProps<{ parentAction?: () => void }>>(
  function ChangePasswordForm(props) {
    const { isPhone } = useMedia()
    const newPassword = useText()
    const confirmNewPassword = useText()
    const [matchError, setMatchError] = useState(false)
    const [lengthError, setLengthError] = useState(false)

    const handleSubmit = () => {
      if (newPassword.value && newPassword.value?.length < 6) {
        setLengthError(true)
      } else if (confirmNewPassword.value !== newPassword.value) {
        setMatchError(true)
      } else {
        setLengthError(false)
        setMatchError(false)
        apirc.me.api.changePassword
          .fetch({
            password: newPassword.value ?? '',
          })
          .then(() => {
            adminNotifyPushStore.notificationShow = true
            adminNotifyPushStore.notificationTitle = '密碼更改成功'
            props.parentAction && props.parentAction()
          })
      }
    }
    return (
      <div css={flex.v.allCenter}>
        <div
          css={css`
            ${flex.v.allCenter};
            background: ${WHITE}bb;
            margin: 16px ${isPhone ? 8 : 16}px 0px;
            padding: 16px;
            border-radius: 8px;
            gap: 8px;
          `}
        >
          <TextField
            size='small'
            variant='outlined'
            type='password'
            placeholder='輸入新密碼'
            value={newPassword.value}
            onChange={newPassword.acts.onChange}
          />
          <TextField
            size='small'
            variant='outlined'
            type='password'
            placeholder='再輸入一次新密碼'
            value={confirmNewPassword.value}
            onChange={confirmNewPassword.acts.onChange}
            onKeyDown={e => {
              if (e.key === 'Enter') handleSubmit()
            }}
          />
          {lengthError ? <p css={errorMessageCss}>密碼長度必須大於 6</p> : null}
          {matchError ? <p css={errorMessageCss}>兩次密碼不相同</p> : null}
          <Button
            fullWidth
            color='warning'
            variant='contained'
            onClick={() => {
              handleSubmit()
            }}
          >
            更新
          </Button>
        </div>
      </div>
    )
  },
)

const errorMessageCss = css`
  color: ${globalRed.r700};
  font-size: 12px;
`

export default ChangePasswordForm
