import { memo, SyntheticEvent } from 'react'
import { Snackbar, SnackbarCloseReason, SnackbarOrigin, Alert, AlertColor } from '@mui/material'

export const SnackbarMessage = memo<
  ReactProps<{
    open: boolean
    closeDelegate: (
      event: Event | SyntheticEvent<Element, Event>,
      reason?: SnackbarCloseReason,
    ) => void
    message: string
    /** 預設顯示於下方中間 */
    anchorOrigin?: SnackbarOrigin
    duration?: number
    severity?: AlertColor
  }>
>(function SnackbarMessage({ anchorOrigin, open, duration, closeDelegate, message, severity }) {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin ?? { vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={duration ?? 2000}
      onClose={closeDelegate}
    >
      <Alert onClose={closeDelegate} severity={severity ?? 'error'} variant='filled'>
        {message}
      </Alert>
    </Snackbar>
  )
})
