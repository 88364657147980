/* eslint-disable no-inner-declarations */
import produce, { Draft, enableMapSet } from 'immer'
import pipe from 'ramda/src/pipe'
import create, { State, StateCreator } from 'zustand'
import { expectError, expectType } from '~/utils/tsd'
import { __TEST__ } from '~/utils/__TEST__'

enableMapSet()

const immerMiddleware =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>,
  ): StateCreator<T> =>
  (set, get, api) =>
    config(fn => set(produce<T>(fn)), get, api)

export const createStore = pipe(immerMiddleware, create) as typeof create

/* istanbul ignore next */
if (__TEST__) {
  const useStore = createStore<{
    value: number
  }>((set, get) => {
    return {
      value: 123,
    }
  })

  function TestComponent() {
    useStore(state => {
      expectType<number>(state.value)
    })

    return <div></div>
  }

  expectType<number>(useStore.getState().value)

  /** @ts-expect-error error type check */
  expectError<string>(useStore.getState().value)

  /** @ts-expect-error error type check */
  expectError<void>(useStore.getState().nonExistProp)
}
