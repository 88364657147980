import { proxy } from 'valtio'
import { ContractInfo } from '~/configs/StaticJsonAPIs'
import type { staticStore } from '~/pages/heineken_template/_private/staticStore'

/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const staticrcStore = proxy({
  contractInfo: {} as Record<string, ContractInfo>,
})
