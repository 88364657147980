import { Fragment, memo } from 'react'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import {
  useIntraday,
  useLatestTradeDate,
} from '~/modules/screener/containers/useStockScreenerResource'
import { useCommonSymbolList } from '~/modules/SDK/Symbol/useCommonSymbolList'
import { useStaticContractInfo } from '~/modules/SDK/Symbol/useSymbolContractInfo'
import { useSymbolDictionary } from '~/modules/SDK/Symbol/useSymbolDictionary'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'

const BackendDataComponent = memo<ReactProps>(function BackendDataComponent() {
  useSymbolDictionary()
  useLatestTradeDate()
  useIntraday()
  useCommonSymbolList()
  useStaticContractInfo()

  useIntervalNow(() => {
    staticStore.fetchAndUpdateState()
  }, 60 * 60 * 1000)

  return <Fragment></Fragment>
})

export default BackendDataComponent
