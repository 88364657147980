import Router from 'next/router'
import { GA_TRACKING_ID } from '~/configs/GA_TRACKING_ID'

export const gaAPI = {
  initPageViewOnRouteChange() {
    Router.events.on('routeChangeComplete', url => gaAPI.pageview(url))
  },
  pageview(url: string) {
    gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  },
  event(options: { action: string; category: string; label?: string; value?: number }) {
    gtag('event', options.action, {
      event_category: options.category,
      event_label: options.label,
      value: options.value,
    })
  },
}
