import { Tooltip, TooltipProps } from '@mui/material'
import { memo } from 'react'

/**
 * ## WHY
 *
 * 使用這個 AppTooltip 來客製我們自己的預設 props；用來取代 import { Tooltip } from '@mui/material'
 *
 * ## memo
 *
 * 開發文件 https://material-ui.com/zh/api/tooltip/
 */
export const AppTooltip = memo<TooltipProps>(function AppTooltip(props) {
  return (
    <Tooltip
      placement={'top'}
      enterDelay={0}
      leaveDelay={0}
      enterTouchDelay={350}
      arrow
      {...props}
    >
      {props.children}
    </Tooltip>
  )
})
