import { AnyObject } from 'tsdef'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { expectType } from '~/utils/tsd'
import type { Option } from '~/modules/options/utils/Option'

export class MarketDataAPI extends Urlu {
  /** 轉折資訊 */
  pivotData = new Urlu.Query(
    (params: {
      /**
       * - E.g. `'TX_15K'`
       * - E.g. `'YM_60K'`
       * - E.g. `'TWN_15K'`
       */
      symbolStringWithTimeframe: string
    }) => {
      return this.request.axios
        .get<{
          id?: string
          action: ('' | '0' | '1' | '-1' | string)[]
          current: {
            price: number
            t: ISO8601
          }
          pivot: {
            price: number
            t: ISO8601
          }[]
          type: '多方觀察盤' | '偏空收斂' | '多方喇叭' | string
        }>('/latest_value', {
          params: {
            key: `pivot_${params.symbolStringWithTimeframe.toUpperCase()}`,
          },
        })
        .then(res => res.data)
    },
  )

  /** 期天自制的vix波動率 */
  vixInfo = new Urlu.Query(() => {
    return this.request.axios
      .get<{ value: number }>('/latest_value', {
        params: { key: 'atm_iv' },
      })
      .then(res => res.data)
  })

  /** 大戶力道、大互掛單 */
  dashboardData = new Urlu.Query(
    (params: {
      /**
       * - `'dashboard_bs_indicator'`：大戶力道
       * - `'dashboard_trade_info'`：大戶掛單
       */
      target: 'dashboard_bs_indicator' | 'dashboard_trade_info'
    }) => {
      type DashboardLatestValue = {
        /**
         * 用於顯示純數字之數值
         *
         * - E.g. `1057`
         */
        value: number
        /**
         * 用於 Gauge 指針角度
         *
         * - E.g. `0.4467661580737735`
         */
        display_value: number
      }

      return this.request.axios
        .get<DashboardLatestValue>('/latest_value', {
          params: {
            key: params.target,
          },
        })
        .then(res => res.data)
        .catch(error => {
          console.error(`[ERROR/fallback] ${error?.message}`)
          const fallback: DashboardLatestValue = {
            value: 0,
            display_value: 0,
          }

          return fallback
        })
    },
  )

  /**
   * @example
   *   //
   *   // return
   *   { 1101: { name: '台泥', percentage: 0.5964 }, 1102: { name: '亞泥', percentage: '0.3525' } , ..., }
   */
  stockWeight = new Urlu.Query((params: { exchange: 'TWSE' | 'TPEX' }) => {
    return this.request.axios
      .get<{
        [k in string]: {
          name: string
          percentage: number
        }
      }>(
        // WORKAROUND: 這個後輟斜線一定得加，不然會 cors ERROR
        `/stock_weight/`,
        { params },
      )
      .then(res => Object.entries(res.data))
  })

  /** 缺註釋 */
  amplitudeStatistic = new Urlu.Query(
    (params: {
      symbol: string
      from: number
      to: number
      bins: number
      resolution: string
      outlier: number
    }) => {
      return this.request.axios
        .get<
          {
            max: number
            min: number
            mean: number
            stddev: number
            resolution: string
            sample: { key: number; value: number }[]
          }[]
        >(`/amplitude_statistic`, { params })
        .then(res => res.data)
    },
  )

  /** 缺註釋 */
  unifyTable = new Urlu.Query((params: { from: number; to: number }) => {
    return this.request.axios
      .get<{
        t: number[]
        foreigner_txf: { net_oi_volume: number[]; oi_diff: number[] }
        foreigner_mxf: { net_oi_volume: number[]; oi_diff: number[] }
        foreigner_opt: {
          net_oi_value: number[]
          net_oi_volume: number[]
          oi_value_diff: number[]
          oi_volume_diff: number[]
        }
        foreigner_twse: { trading_value_diff: number[] }
        foreigner_tpex: { trading_value_diff: number[] }
        dealer_txf: { net_oi_volume: number[]; oi_diff: number[] }
        dealer_mxf: { net_oi_volume: number[]; oi_diff: number[] }
        dealer_opt: {
          net_oi_value: number[]
          net_oi_volume: number[]
          oi_value_diff: number[]
          oi_volume_diff: number[]
        }
        dealer_twse: { trading_value_diff: number[] }
        dealer_tpex: { trading_value_diff: number[] }
        large_trader_futures_last5: { oi: number[]; oi_diff: number[] }
        large_trader_futures_top5: { oi: number[]; oi_diff: number[] }
        large_trader_futures_top10: { oi: number[]; oi_diff: number[] }
        large_trader_options_last5: { oi: number[]; oi_diff: number[] }
        large_trader_options_top5: { oi: number[]; oi_diff: number[] }
        large_trader_options_top10: { oi: number[]; oi_diff: number[] }
        retail_oi: { oi: number[]; oi_diff: number[] }
        trust_mxf: { net_oi_volume: number[]; oi_diff: number[] }
        trust_txf: { net_oi_volume: number[]; oi_diff: number[] }
        trust_tpex: { trading_value_diff: number[] }
        trust_twse: { trading_value_diff: number[] }
      }>(`/unify_table`, { params })
      .then(res => res.data)
  })

  /** 缺註釋 */
  diffStatistic = new Urlu.Query(
    (params?: {
      symbol: string
      from: number
      to: number
      bins: number
      resolution: string
      outlier: number
    }) => {
      return this.request.axios
        .get<
          {
            max: number
            min: number
            pos_mean: number
            neg_mean: number
            pos_stddev: number
            neg_stddev: number
            resolution: string
            sample: { key: number; value: number }[]
          }[]
        >(`/diff_statistic`, { params })
        .then(res => res.data)
    },
  )

  /** 目前可用合約、所有履約價格的未平倉量 */
  taifexOptionsOi = new Urlu.Query((params?: { date?: string }) => {
    return this.request.axios
      .get<Option.TaifexOptionsOiRemoteData[]>(`/taifex_options_oi`, { params })
      .then(res => res.data)
  })

  /** 股期權重排行前30 */
  stockFuturesWeight = new Urlu.Query((params?: { date?: string }) => {
    return this.request.axios
      .get<{
        [key: string]: {
          symbol: string
          percentage: number
        }
      }>(`/stock_futures_weight`, { params })
      .then(res => res.data)
  })

  /** 專用 */
  daddy960 = {
    /** 股期資訊 */
    stkfutTradeInfo: new Urlu.Query((params?: { date?: string }) => {
      return this.request.axios
        .get<{
          [key: string]: {
            near_month: number
            next_month: number
            total: number
          }
        }>(`/stkfut_tradeinfo`, { params })
        .then(res => res.data)
    }),

    /** 股威交易資訊 外資、散戶、自營 */
    chip960TradeInfo: new Urlu.Query((params?: { date?: string }) => {
      return this.request.axios
        .get<{
          tx_bvav: number
          mtx_bvav: number
          mtx_tbta: number
        }>(`/chip960_tradeinfo`, { params })
        .then(res => res.data)
    }),
  }

  averageVolume = new Urlu.Query((params: { symbol: string }) => {
    return this.request.axios
      .get<{
        symbol: string
        interval: number
        data: {
          time: string
          avg_volume: number
        }[]
      }>(`/average_volume/`, {
        params: params,
      })
      .then(res => res.data)
  })
}

/* istanbul ignore next */
function TypingTesting() {
  const api = new MarketDataAPI(``)

  //
  //
  const unifyTable = api.unifyTable.useSWR(
    {
      from: 123,
      to: 123,
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 15000,
    },
  )

  //
  //
  const stkfutTradeInfo = api.daddy960.stkfutTradeInfo.useSWR(undefined, {
    revalidateOnFocus: false,
    refreshInterval: 15000,
  })

  expectType<number | undefined>(stkfutTradeInfo.data?.[0]?.total)
}
