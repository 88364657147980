import { AxiosResponse } from 'axios'
import useSWR from 'swr'
import { useAsync } from 'react-use'
import { AnyFunction, FirstArgument, NonNil, UndefinableProps, nil } from 'tsdef'
import urlcat from 'urlcat'
import { SWRConfiguration } from 'swr'

/** ⭐️ 創建一筆 async http request API 之用 */
export class UrluOfQuery<
  Fetcher extends AnyFunction = unknown extends (...args: infer A) => infer U
    ? (...args: A) => U
    : never,
> {
  constructor(fetcher: Fetcher) {
    type FetcherParams<T = FirstArgument<Fetcher>> = undefined extends T ? void : T

    type FetcherReturn = Awaited<ReturnType<Fetcher>>

    //
    //
    this.fetch = fetcher

    /**
     * useSWR 的第一個參數，必須是「唯一的 key as string」
     *
     * 如果我們不同 API 都用同樣的 key 會造成其餘相同 key 的不同 API 打不出去
     *
     * 這裡利用 toString() 來達到自動定義唯一 key 的特性
     */
    const fetchId = this.fetch.toString().replaceAll(/[\s\n:]/gi, '')

    //
    //
    this.useAsync = function useAsync_UrluOfQuery(params: FetcherParams) {
      const res = useAsync(async () => {
        return await this.fetch(params)
      }, [params && urlcat(fetchId, { ...params })])

      return {
        data: res.value as undefined | FetcherReturn,
        loading: res.loading,
        error: res.error,
      }
    }

    //
    //
    this.useSWR = function useSWR_UrluOfQuery(params: FetcherParams, swrConfig?: SWRConfiguration) {
      const res = useSWR(
        params ? urlcat(fetchId, { ...params }) : fetchId,
        async () => {
          return await this.fetch(params)
        },
        swrConfig,
      )

      return {
        data: res.data as undefined | FetcherReturn,
        loading: res.isLoading,
        error: res.error,
      }
    }
  }

  /**
   * ⭐️ axios 的 get 或 post
   *
   * 依最初定義的 function 而定
   */
  fetch

  /**
   * ⭐️ 給 react 用的 hook
   *
   * 使 react 端開箱即用！不須要再自己封裝一次 hook
   */
  useAsync

  /**
   * ⭐️ 給 react 用的 hook
   *
   * 使 react 端開箱即用！不須要再自己封裝一次 hook
   */
  useSWR

  // private isAxiosResponse<ReturnJSON>(res: unknown): res is AxiosResponse<ReturnJSON> {
  //   return res !== null && typeof res === 'object' && 'status' in res && 'data' in res
  // }

  // private isEmpty(res: unknown): res is nil {
  //   return res === null || res === undefined
  // }
}
