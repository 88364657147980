import Emittery from 'emittery'
import { EventData } from '~/modules/SDK/Events/EventData'
import { EventWhyThisDeprecated } from '~/modules/SDK/Events/EventWhyThisDeprecated'

/**
 * # @deprecated 請見 {@link EventWhyThisDeprecated}
 *
 * @deprecated
 */
export const eventEmitter = new Emittery<EventData>()
