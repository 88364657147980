import type { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { createStore } from '~/store/createStore'

export type TW50 = string[]
export type OTC50 = string[]
export type OsFuturesList = {
  overall: string[]
  agriculture: string[]
  metal: string[]
  energy: string[]
}

export type StockFutures = { [key in string]: string }

export type StockCategory = {
  name: string
  index_symbol: string
  symbols: string[]
}[]

/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const useCommonSymbolListStore = createStore<{
  /** 上市50 */
  tw50: TW50 | undefined
  /** 上櫃50 */
  otc50: OTC50 | undefined
  /** 海外期貨，依照類別作為key分 */
  osfuturesList: OsFuturesList | undefined
  /** Stock 對應的 期貨商品 */
  stockFuturesMapper: StockFutures | undefined
  stockCategory: StockCategory
}>((set, get) => {
  return {
    tw50: [],
    otc50: [],
    osfuturesList: { overall: [], agriculture: [], metal: [], energy: [] },
    stockFuturesMapper: {},
    stockCategory: [],
  }
})
