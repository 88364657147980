import { reduce } from 'lodash'
import { proxy } from 'valtio'
import { agentConfigs2 } from '~/configs/agentConfigs'
import { AgentProduct } from '~/configs/AgentProduct'
import { ENV } from '~/configs/ENV'
import { expectType } from '~/utils/tsd'
import { __TEST__ } from '~/utils/__TEST__'

const AGENT_PRODUCT = ENV.NEXT_PUBLIC_TARGET_AGENT_PRODUCT || ''
const TARGET_PAGES = ENV.NEXT_PUBLIC_TARGET_PAGES || ''

export const fr_agents = proxy(
  new (class {
    /** E.g. `'futuresai'` or `'winner98'` or `'bole'` */
    agent = AGENT_PRODUCT.replace(/@.*/, '')

    /** E.g. `'futuresai@web'` or `'winner98@web_A'` or `'bole@web'` */
    agentProduct = AGENT_PRODUCT

    /** E.g. `heineken_template` */
    targetPages = TARGET_PAGES

    /** E.g. `'web'` or `'web_A'` or `'exchange'` or `'canSubStocks'` */
    product = AGENT_PRODUCT.replace(/.*?@/, '')

    config = agentConfigs2[AGENT_PRODUCT]

    /**
     * ## 判斷指定部署權限
     *
     * ### 判斷 agent 和 product 完全與「packageJson給env」相同
     *
     * @example
     *   // ## 當 `NEXT_PUBLIC_TARGET_AGENT_PRODUCT=futuresai@web`
     *   agentStore.is['futuresai@web'] // true
     *   agentStore.is['futuresai@read_public_account'] // false
     *   agentStore.is['winner@web_A'] // false
     *
     *   if (agentStore.is['futuresai@web']) {
     *     return <FuturesaiComponents />
     *   } else {
     *     return <OthersComponents />
     *   }
     */
    is = reduce(
      AgentProduct,
      (returnValue, reduceItem) => {
        returnValue[reduceItem] = reduceItem === AGENT_PRODUCT
        return returnValue
      },
      {} as Record<AgentProduct, boolean>,
    )
  })(),
)

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* istanbul ignore next */
if (__TEST__) {
  expectType<boolean>(fr_agents.is.null)
  expectType<boolean>(fr_agents.is['futuresai@exchange'])
  expectType<boolean>(fr_agents.is['futuresai@web'])
  expectType<boolean>(fr_agents.is['ai919@web'])
  expectType<boolean>(fr_agents.is['winner@web_A'])

  /** 不存在的 AgentProduct 靜態值定義 */
  /** @ts-expect-error */
  fr_agents.is['futuresai2@web']
}
/* eslint-enable @typescript-eslint/ban-ts-comment */
