import { useLocalStorage } from 'react-use'
import { useAppNamespace } from '~/hooks/useAppNamespace'

type UseLocalStorageParams = Parameters<typeof useLocalStorage>

/**
 * Automatic prefixing the 「futuresai」and 「{agent}」 as the namespaces. implements based on
 * 「react-use.useLocalStorage」
 *
 * @example
 *   ## Given the key "darkmode" will store in key name "futuresai:hyt888:darkmode"
 *
 *   const [value, setValue, remove] = useAppLocalStorage('darkmode')
 */
export const useAppLocalStorage = <V extends UseLocalStorageParams[1] | undefined>(
  key: UseLocalStorageParams[0],
  initialValue?: V | undefined,
  options?: UseLocalStorageParams[2],
) => {
  const NS = useAppNamespace(key)

  return useLocalStorage<V>(
    NS,
    initialValue,
    /** @ts-expect-error 因為只需將汎型 V 傳入，便不影響後續類型推斷 */
    options,
  )
}
