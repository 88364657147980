import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useMount, useUnmount } from 'react-use'
import useSWR from 'swr'
import { proxy, useSnapshot } from 'valtio'
import { apirc } from '~/configs/apirc'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import dayAPI from '~/utils/dayAPI'

/** 伺服器時間 */
class FrServerTime {
  state = proxy({
    serverTime: null as null | Dayjs,
  })

  useServerTime = () => {
    return useSnapshot(this.state).serverTime
  }

  useInstall = (options?: { intervalMs?: number }) => {
    const [datetime, setDatetime] = useState(dayAPI().format())

    useMount(() => {
      debugAPI.fr_serverTime.log(`FrServerTime: useInstall(): 已安裝`)
    })

    useIntervalNow(() => {
      setDatetime(dayAPI().format())
    }, options?.intervalMs ?? 1000 * 60)

    const serverTimeUnix =
      useSWR(datetime, apirc.tvAPIs.fetchServerTime, {
        keepPreviousData: true,
      }).data ?? 0

    useEffect(() => {
      this.state.serverTime = dayAPI(serverTimeUnix * 1000)
    }, [serverTimeUnix])

    useUnmount(() => {
      debugAPI.fr_serverTime.log(`FrServerTime: useInstall(): 已解除`)
    })
  }
}

export const fr_serverTime = proxy(new FrServerTime())
