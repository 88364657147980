import { LiteralUnion } from 'type-fest'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { StaticTypes } from '~/pages/heineken_template/_private/StaticTypes'

export type ContractInfo = {
  timezone: LiteralUnion<
    'US/Central' | 'US/Eastern' | 'Asia/Hong_Kong' | 'Asia/Singapore' | 'Asia/Taipei',
    string
  >
  exchange?: LiteralUnion<'XTAI' | 'XHKG', string>
  /** 幣別 */
  currency: LiteralUnion<'TWD' | 'USD' | 'JPY' | 'HKD' | 'SGD', string>
  /** 每點價值 */
  big_point_value: LiteralUnion<50 | 200 | 1000, number>
  /** 小數點位數 */
  pricescale: LiteralUnion<1 | 100 | 1000 | 2500, number>
  /**
   * 最小跳動
   *
   * MinimalPossiblePriceChange = minmov / pricescale
   */
  minmov: LiteralUnion<25, number>
  trading_interval: {
    open_time: LiteralUnion<'15:00' | '19:00"' | '08:45', string>
    close_time: LiteralUnion<'05:00' | '13:45', string>
    cross_day: boolean
  }[]
}

export class StaticJsonAPIs extends Urlu {
  constructor() {
    super('https://storage.googleapis.com')
  }

  get request() {
    return super.request.removeJwt
  }

  async fetchContractInfo() {
    return this.request.axios
      .get<Record<string, ContractInfo>>('/symbol-config/contract_info.json')
      .then(res => res.data)
  }

  /** 純股票代碼 */
  async fetchSymbolOfStockToNameMapp() {
    const symbolOfStockToNameMapp = await this.request.axios
      .get<StaticTypes.CodeToNameMapp>('/symbol-config/stock_code_to_chinese.json')
      .then(res => res.data)

    return symbolOfStockToNameMapp
  }

  /** 純期貨代碼 */
  async fetchSymbolToNameMapp() {
    const symbolToNameMapp = await this.request.axios
      .get<StaticTypes.CodeToNameMapp>('/symbol-config/code_to_chinese.json')
      .then(res => res.data)

    return symbolToNameMapp
  }
}
