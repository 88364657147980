import { useCallback, useMemo, useState } from 'react'

export const useText = (text?: string) => {
  const [state, setState] = useState(text)

  const onChange = useCallback((event: React.BaseSyntheticEvent) => {
    setState(event.target.value)
  }, [])

  const clear = useCallback(() => {
    setState('')
  }, [])

  return useMemo(() => {
    return {
      value: state,
      acts: {
        clear,
        onChange,
        setState,
      },
    }
  }, [clear, onChange, state])
}
