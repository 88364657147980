import { Dayjs } from 'dayjs'
import { defaultsDeep } from 'lodash'
import { LiteralUnion, SnakeCase, SnakeCasedPropertiesDeep } from 'type-fest'
import { Option } from '~/modules/options/utils/Option'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import dayAPI from '~/utils/dayAPI'

export class OptionsAPI extends Urlu {
  async fetchOpbs(params?: {
    /** E.g. `dayAPI().subtract(1, 'day')` */
    from?: Dayjs | undefined
    /** E.g. `dayAPI().add(1, 'day') */
    to?: Dayjs | undefined
    /** E.g. `'202010'` */
    contract_month?: string
    q_gte?: number
    q_lte?: number
    m_gte?: number
    m_lte?: number
  }) {
    return await this.request.axios.get(`/v2/opbs`, {
      params: {
        q_gte: params?.q_gte,
        q_lte: params?.q_lte,
        m_gte: params?.m_gte,
        m_lte: params?.m_lte,
        from: String((params?.from?.toDate().getTime() ?? 0) / 1000),
        from__DEBUG__: params?.from?.format(),
        to: String((params?.to?.toDate().getTime() ?? 0) / 1000),
        to__DEBUG__: params?.to?.format(),
      },
    })
  }

  async fetchContracts(params?: {
    /** E.g. `dayAPI().subtract(1, 'day')` */
    from?: Dayjs | undefined
    /** E.g. `dayAPI().add(1, 'day') */
    to?: Dayjs | undefined
  }) {
    params = defaultsDeep(params, {
      from: dayAPI(),
      to: dayAPI(),
    }) as NonNullable<typeof params>

    return await this.request.axios.get<SnakeCasedPropertiesDeep<Option.OpcontractRemoteData>>(
      `/opcontracts`,
      {
        params: {
          from: String(params.from?.unix()),
          to: String(params.to?.unix()),
          from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm'),
          to__DEBUG__: params.to?.format('YYYY-MM-DD HH:mm'),
        },
      },
    )
  }

  async fetchQuote(params?: { contract_month?: string; to?: Dayjs }) {
    params = defaultsDeep(params, {
      to: dayAPI(),
    })

    return await this.request.axios.get<
      {
        close: LiteralUnion<65, number>
        high: LiteralUnion<72, number>
        low: LiteralUnion<64, number>
        open: LiteralUnion<65, number>
        symbol: LiteralUnion<'TXO14400X2', string>
        volume: LiteralUnion<595, number>
      }[]
    >(`/options_price`, {
      params: {
        contract_month: params?.contract_month,
        to: params?.to?.unix(),
      },
    })
  }
}
