import { css } from '@emotion/react'
import { createTheme as createMuiTheme } from '@mui/material/styles'
import { globalBlue, globalGrey, globalYellow } from '~/modules/AppLayout/Colors'
import { createTheme } from '~/modules/theme/createTheme'

const color = '#000'
const backgroundColor = globalGrey.g50

/** @deprecated 這套設計出來後，大伙沒有共識，在隨後加零有一套新的 theme 模式，遵守她的，勿多頭馬車 */
export const lightTheme = createTheme({
  body: css`
    color: ${color};
    background-color: ${backgroundColor};
  `,
  components: {
    'MuiPaper-root': css`
      color: ${color};
      background-color: '#373b3d';
    `,
  },
})

export default createMuiTheme({
  palette: {
    mode: 'light',
    background: { default: backgroundColor },
  },
})

export const futuresaiTheme = createMuiTheme({
  palette: {
    primary: {
      main: globalBlue.bA400,
    },
    secondary: {
      main: globalYellow.y700,
    },
    mode: 'light',
    background: { default: backgroundColor },
  },
})
