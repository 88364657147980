import { EventWhyThisDeprecated } from '~/modules/SDK/Events/EventWhyThisDeprecated'

/**
 * # @deprecated 請見 {@link EventWhyThisDeprecated}
 *
 * @deprecated
 */
export enum EventString {
  /** Signalr Socket 連接時 */
  signalrOnConnect = 'signalrOnConnect',
  /** Signalr 開始訂閱商品 */
  signalrSubscribeAdd = 'signalrSubscribeAdd',
  /** 電腦或手機休眠時 */
  visibilityChange = 'visibilityChange',
  /** 當 Chart 準備好時（異步） */
  onTVChartReady = 'onTVChartReady',
  /** 每當 ChartSymbol 改變時（異步） */
  onTVChartSymbolChanged = 'onTVChartSymbolChanged',
  /** 每當 ChartInterval 改變時（異步） */
  onTVChartIntervalChanged = 'onTVChartIntervalChanged',
}
