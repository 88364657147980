/**
  # webpack 直接入參
*/
// @ts-expect-error 此處全域變數由 webpack 定義
const APP_ENV = _APP_ENV as {
  /** 如果沒有 version 則以七碼 commit hash 顯示之 */
  VERSION: string
  /** 完整顯示 commit hash */
  COMMITHASH: string
  BRANCH: string
} as const

/**
  # 環境變數

  ## 唯讀
 */
export const ENV = {
  NEXT_PUBLIC_TARGET_AGENT_PRODUCT: process.env.NEXT_PUBLIC_TARGET_AGENT_PRODUCT,
  NEXT_PUBLIC_TARGET_PAGES: process.env.NEXT_PUBLIC_TARGET_PAGES,
  ...APP_ENV,
} as const
