import { AgentConfig2 } from '~/configs/agentConfigs'
import { AgentProduct } from '~/configs/AgentProduct'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { MeTypes } from '~/modules/SDK/me/MeTypes'
import { createStore } from '~/store/createStore'

export const useMeStore = createStore<{
  /**
   * ## 不是很確定這個是否 `trial` 和 `trialCode`(已棄用) 共用
   *
   * # @deprecated {@link WhyThisDeprecated.TrialCode}
   */
  isValidTrialUser: boolean
  /**
   * 純的 Agent
   *
   * - E.g. `'futuresai'`
   * - E.g. `'good178'`
   * - E.g. `'sinopac'`
   *
   * 不會有舉例
   *
   * - E.g. `'sinopac@web'`
   */
  agentName: string
  /**
   * 純的 Product
   *
   * - E.g. `'web'`
   * - E.g. `'web_stock'`
   * - E.g. `'os_web_stock'`
   * - 不會有舉例
   * - E.g. `'sinopac@web_stock'`
   */
  product: string
  agentConfig: AgentConfig2
  /**
   * 「純的 Agent」組合「純的 Product」
   *
   * - E.g. `'futuresai@web'`
   */
  agentProduct: AgentProduct
  /** 存於後端的會員資料 */
  meUserState: null | MeTypes.MeUserState
  /** 存於後端的會員詳細資料 */
  meInfoState: null | MeTypes.MeInfoState
  /** 存於 Firebase 端的會員或訪客資料 */
  meFirebaseState: null | MeTypes.MeFirebaseState
  init(): void
  // useInit(agentName?: AgentName): void
}>((set, get, api) => {
  return {
    init() {
      set(state => {
        state.agentProduct = fr_agents.agentProduct
        state.agentName = fr_agents.agent
        state.product = fr_agents.product
        state.agentConfig = fr_agents.config
      })
    },
    isValidTrialUser: false,
    agentProduct: fr_agents.agentProduct,
    agentName: fr_agents.agent,
    product: fr_agents.product,
    agentConfig: fr_agents.config,
    meFirebaseState: null,
    meUserState: null,
    meInfoState: null,
  }
})
