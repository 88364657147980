import { LiteralUnion } from 'type-fest'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { Pivot } from '~/modules/strength-state/usePivotState'

export class StrengthAPI extends Urlu {
  /** 大戶掛單、大戶力道 */
  public async getDashboardData(
    /**
     * - `'dashboard_bs_indicator'`：大戶力道
     * - `'dashboard_trade_info'`：大戶掛單
     */
    target: LiteralUnion<'dashboard_bs_indicator' | 'dashboard_trade_info', string>,
  ) {
    type DashboardLatestValue = {
      /**
       * 用於顯示純數字之數值
       *
       * - E.g. `1057`
       */
      value: number
      /**
       * 用於 Gauge 指針角度
       *
       * - E.g. `0.4467661580737735`
       */
      display_value: number
    }

    try {
      const data = await this.request.axios
        .get<DashboardLatestValue>('/latest_value', {
          params: {
            key: target,
          },
        })
        .then(res => res.data)

      return data
    } catch (error: any) {
      console.error(`[ERROR/fallback] ${error?.message}`)
      const fallback: DashboardLatestValue = {
        value: 0,
        display_value: 0,
      }

      return fallback
    }
  }

  /** 大戶掛單 v2 */
  public async getDashboardTradeInfo() {
    try {
      const data = await this.request.axios
        .get<{
          /**
           * 顯示絕對數字之數值
           *
           * - E.g. `1057`
           */
          value: number
          /**
           * 指針角度
           *
           * - E.g. `0.4467661580737735`
           */
          display_value: number
        }>('/latest_value', {
          params: { key: 'dashboard_trade_info' },
        })
        .then(res => res.data)

      return data
    } catch (error) {
      return 0
    }
  }

  /** 期天自制的vix波動率 */
  public async getVixInfo() {
    {
      const data = await this.request.axios
        .get<{ value: number }>('/latest_value', {
          params: { key: 'atm_iv' },
        })
        .then(res => res.data)

      return data
    }
  }

  /** 轉折資訊 */
  public async getPivotData(
    /**
     * - E.g. `'TX_15K'`
     * - E.g. `'YM_60K'`
     * - E.g. `'TWN_15K'`
     */
    symbolStringWithTimeframe = 'TX_15K',
  ): Promise<Pivot | undefined> {
    const data = await this.request.axios
      .get<Pivot>('/latest_value', {
        params: {
          key: `pivot_${symbolStringWithTimeframe.toUpperCase()}`,
        },
      })
      .then(res => res.data)

    return data
  }
}
