import { AnyFunction, IsAny } from 'tsdef'
import { Container, createContainer as createContainerOrigin } from 'unstated-next'
import { expectType } from '~/utils/tsd'
import { __TEST__ } from '~/utils/__TEST__'

/**
 * ## 🟢 Create a UnstatedState Container which has additional features
 *
 * > This to instead of "createContainer" of "unstated-next" itself
 *
 * 1. has auto-assigned "displayName"
 *
 * > Based on the "createContainer" of unstated-next original, this util function to be make sure
 * the "Provider" to has the "displayName" according to the name of given function of "hook"
 *
 * 1. "useState", a replacement for "useContainer" with optional "Provider" support
 *
 * > Get rid of the error throw "You have to wrapper with Provider", useful for communication of
 * containers between multi-modules
 *
 * @example
 *   // ~/hooks/useXxxYyyState
 *
 *   export const useXxxYyyState = createContainer(() => {
 *     return {}
 *   })
 */
export const createContainer = <F extends AnyFunction>(reactHook: F) => {
  const container: AppContainerType<F> = (createContainerOrigin as AnyExplicit)(reactHook)

  if (!reactHook.name) {
    console.warn(
      `${createContainer.name} Warn: 你傳入的 React Hook 沒有名字，將造成 React devtool 失去 Provider 名稱標示，將箭頭函式轉換成 function useHook() 以解決此問題。`,
    )
  }

  container.Provider.displayName = `Provider:${reactHook.name}`

  let state: ReturnType<F> | null = null

  container.useState = () => {
    try {
      state = container.useContainer()
    } catch (error) {
      state = null
    }
    return state
  }

  return container
}

export type AppContainerType<F extends AnyFunction = AnyFunction> = ContainerType<F> & {
  /**
   * The replacement for "useContainer" with optional Provider support
   *
   * @example
   *   // ## With "useContainer" You have to wrapper container with "Provider"
   *
   *   const RootComponent = props => {
   *     return <ContainerProvider>{props.children}</ContainerProvider>
   *   }
   *
   *   const SubComponent = props => {
   *     const state = useContainer()
   *
   *     return <RootComponent>{props.children}</RootComponent>
   *   }
   *
   * @example
   *   // ## With "useState" You DONT have to wrapper container with "Provider", its optional
   *
   *   const SubComponent = props => {
   *     // If you dont wrapper container, it's null, no longer throw an error
   *     const state = useContainer()
   *
   *     useMount(() => {
   *       state.acts.doSome()
   *     })
   *
   *     return <span>{props.children}</span>
   *   }
   */
  useState: () => ReturnType<F> | null
  Provider: ContainerType<F>['Provider'] & {
    displayName: string
  }
}

/** ## The unstated-next container type */
export type ContainerType<F extends AnyFunction = AnyFunction> = Container<
  ReturnType<F>,
  ParametersHead<F>
>

/* istanbul ignore next */
if (__TEST__) {
  /* eslint-disable:start no-inner-declarations */
  const useMyAuth = (a: number, b: string, cd: { d: { bo: boolean } }) => {
    const fn1 = (c: boolean) => a

    return { act: { fn1 } }
  }

  const myAuthContainer = createContainer(useMyAuth)

  /* eslint-disable no-inner-declarations */
  function TypesTesting() {
    const contn = myAuthContainer.useContainer()

    // contn.act NOT to be Any
    expectType<IsAny<typeof contn.act, true, false>>(false)

    expectType<ReturnType<typeof useMyAuth>['act']>(contn.act)
    expectType<ReturnType<typeof useMyAuth>['act']['fn1']>(contn.act.fn1)
    expectType<Parameters<typeof useMyAuth>>([123, '123', { d: { bo: true } }])
    expectType<typeof myAuthContainer['Provider']['displayName']>('123')

    /** @ts-expect-error 預期 displayName 應是 string 不會有 undefined */
    expectType<typeof myAuthContainer['Provider']['displayName']>(undefined)

    return (
      <myAuthContainer.Provider>
        <span>hello world</span>
      </myAuthContainer.Provider>
    )
  }
  /*  eslint-enable no-inner-declarations */
}
