import { createStore } from '~/store/createStore'

/**
 * # 計畫以 {@link backendStore} 替代
 *
 * @deprecated
 */
export const useLatestTradeDateStore = createStore<{
  date: string
  intraday: string
}>((set, get) => {
  return {
    date: '',
    intraday: '',
  }
})
