export const sendToSlack = (text: string) => {
  // TODO: rename this module
  return fetch('https://simple-logging.run.futures-ai.com', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      text: text,
    }),
  })
}
