import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { Admin } from '~/modules/admin/Admin'

export class LineNotifyAPI extends Urlu {
  push = new Urlu.Query((payload: Admin.MessagePayload) => {
    const formData = new FormData()
    Object.entries(payload).map(([k, v]) => {
      formData.append(k, v)
    })
    return this.request.axios.post<Admin.MessageFeedback>(
      'https://line-notify.futures-ai.com/push',
      formData,
    )
  })

  notify = new Urlu.Query((payload: Admin.MessagePayload) => {
    const formData = new FormData()
    Object.entries(payload).map(([k, v]) => {
      formData.append(k, v)
    })
    return this.request.axios.post<Admin.MessageFeedback>(
      'https://line-notify.futures-ai.com/notify',
      formData,
    )
  })
}
