import appGetNamespace from '~/modules/SDK/app/appGetNamespace'

/**
 * @example
 *   const NS = useAppNamespace() // 'futuresai.hyt888'
 *
 * @example
 *   const NS = useAppNamespace('darkmode') // 'futuresai.hyt888.darkmode'
 */
export const useAppNamespace = appGetNamespace
