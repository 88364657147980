import { useTranslation } from 'react-i18next'
import twLocale from '~/i18n/tw.json'
import { ExistsFunction } from 'i18next'
import '~/i18n'

export const useAppTrans = () =>
  /** @ts-expect-error WHY：升上去nextjs@12, react@18 後壞了，暫不知何解 */
  useTranslation() as unknown as Omit<ReturnType<typeof useTranslation>, 'T'> & {
    t: ExistsFunction<keyof typeof twLocale>
  }
