import { css } from '@emotion/react'
import { Button, TextField } from '@mui/material'
import { memo, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { apirc } from '~/configs/apirc'
import useMedia from '~/hooks/useMedia'
import { WHITE } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'

export const ActivationCodeFrom = memo(function ActivationCodeFrom(props) {
  const [response, setResponse] = useState('')
  const [ActivationCode, setActivationCode] = useState('')
  const { isPhone } = useMedia()

  const [sendCodeState, sendCode] = useAsyncFn(
    async (code: string) => await apirc.me.api.postActivationCode.fetch(code),
    [],
  )

  return (
    <div css={flex.v.allCenter}>
      <div
        css={css`
          ${flex.v.allCenter};
          background: ${WHITE}bb;
          margin: 16px ${isPhone ? 8 : 16}px 0px;
          padding: 16px 16px 0;
          border-radius: 8px;
          & > * {
            margin: 8px 0 !important;
          }
        `}
      >
        <TextField
          label='輸入優惠碼'
          value={ActivationCode}
          onChange={event => {
            setActivationCode(event.currentTarget.value)
          }}
        />

        <Button
          fullWidth
          color='warning'
          variant='contained'
          onClick={() => {
            sendCode(ActivationCode)
          }}
        >
          {sendCodeState.loading ? '送出中...' : '送出'}
        </Button>

        {sendCodeState.value && <div>{sendCodeState.value?.activationCode} 已啟用</div>}

        {sendCodeState.error && <div>{sendCodeState.error?.message}</div>}
      </div>
    </div>
  )
})
