import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import twJSON from './tw.json'
import cnJSON from './cn.json'

i18next.use(initReactI18next).init({
  resources: {
    tw: { translation: twJSON },
    cn: { translation: cnJSON },
  },
  fallbackLng: 'tw',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})
