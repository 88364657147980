import merge from 'lodash/merge'
import { useLayoutEffect } from 'react'
import useSWR from 'swr-0-5-6'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import type { staticStore } from '~/pages/heineken_template/_private/staticStore'

/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 初始呼叫所有指標的對應中文列表 對應 useSymbolDictionaryStore
 *
 * @deprecated
 */
export const useSymbolDictionary = () => {
  const stockToChinese = useSWR<{ [key in string]: string }>(
    'https://storage.googleapis.com/symbol-config/stock_code_to_chinese.json',
    { revalidateOnFocus: false },
  )

  const othersToChinese = useSWR<{ [key in string]: string }>(
    'https://storage.googleapis.com/symbol-config/code_to_chinese.json',
    { revalidateOnFocus: false },
  )

  useLayoutEffect(() => {
    if (stockToChinese.data && othersToChinese.data) {
      const dictionary = merge(stockToChinese.data, othersToChinese.data)
      useSymbolDictionaryStore.setState({ dictionary })
    }
  }, [othersToChinese.data, stockToChinese.data])
}
