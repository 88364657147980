import { __NAMESPACE__ } from '~/configs/__NAMESPACE__'
import { useMeStore } from '~/modules/SDK/me/useMeStore'

export default function appGetNamespace(key?: string) {
  const agentName = useMeStore.getState().agentName

  if (key) {
    return `${__NAMESPACE__}.${agentName}.${key}`
  }

  return `${__NAMESPACE__}.${agentName}`
}
